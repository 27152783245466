
import EmployeeSchedule from "@/models/hr/EmployeeSchedule";
import { onBeforeMount, ref, watch } from "vue-demi";
import { useHTTP } from "@/plugins/http";
import dayjs from "dayjs";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import "dayjs/locale/ar-iq";
import { useContext } from "@/plugins/context";
import { useI18n } from "vue-i18n";
import Location from '@/models/company/Location';

export default {
  components: { LoadingSpinner, AlertBadge },
  name: "view-employee-schedule",

  setup() {
    const messages = {
      en: {
        am: "am",
        pm: "pm"
      },
      ar: {
        am: "صباحا",
        pm: "مساءا"
      }
    };
    const { t } = useI18n({ messages, useScope: "global" });
    const selectedDate = ref(dayjs()); //to get the current day
    const scheduleData = ref(new Array<EmployeeSchedule>());
    const locations = ref(new Array<Location>());
    const location =  ref("");
    const http = useHTTP();
    const loading = ref(true);
    const error = ref({ title: "", body: "" });

    const ctx = useContext();

    const getGridBorderClasses = () => {
      return ctx.getDir() == "rtl"
        ? "right-border h-full w-full"
        : "left-border h-full w-full";
    };

    const formatDate = () => {
      return ctx.getDir() === "rtl"
        ? selectedDate.value.locale("ar-iq").format("dddd, DD/M/YYYY")
        : selectedDate.value.format("dddd, DD/M/YYYY");
    };

    const getLocations = async () => {
      try {
        const locResp = await http.get<Location[]>(Location.ENDPOINT);
        locations.value = locResp.map(loc => Location.from(loc));
      } catch (errorResp) {
        if (errorResp.code === 404) {
          locations.value = [];
        } else {
          error.value.title = errorResp.title;
          error.value.body = errorResp.body;
        }
      }
    }

    const getScheduleData = async () => {
      //the date should be added to the path when the api is ready
      let url = `${
        EmployeeSchedule.ENDPOINT
      }?date=${selectedDate.value.format(
        // server is very sensitive to this format. Keep it this way until we update server.
        "YYYY-MM-DD"
      )}`;
      if (location.value != "") {
        url += `&location_id=${location.value}`;
      }
      try {
        const resp = await http.get<EmployeeSchedule[]>(url);
        scheduleData.value = resp.map(es => EmployeeSchedule.from(es));
      } catch (errorResp) {
        if (errorResp.code === 404) {
          scheduleData.value = [];
        } else {
          error.value.title = errorResp.title;
          error.value.body = errorResp.body;
        }
      }
      loading.value = false;
    };

    onBeforeMount(async () => {
      await getLocations();
      await getScheduleData();
    });

    const checkMinutes = (minutes: number) => {
      return minutes ? 1 : 0;
    };

    const checkAmPm = (hour: number) => {
      return hour >= 12 ? "pm" : "am";
    };

    const calculateWorkingHours = (
      startWorkHour: number,
      startWorkMinute: number,
      endWorkHour: number,
      endWorkMinute: number
    ) => {
      const result =
        "grid-column-start:" +
        (startWorkHour * 2 + 1 + checkMinutes(startWorkMinute)) +
        "; grid-column-end:" +
        (endWorkHour * 2 + 1 + checkMinutes(endWorkMinute)) +
        ";";
      return result;
    };

    const dateOnChange = (action: string) => {
      selectedDate.value =
        action === "prev"
          ? selectedDate.value.subtract(1, "day")
          : selectedDate.value.add(1, "day");
      //the new date should be passed to the function
      getScheduleData();
    };

    watch(()=>location.value, function() {
      getScheduleData();
    });

    return {
      scheduleData,
      calculateWorkingHours,
      checkAmPm,
      formatDate,
      dateOnChange,
      loading,
      error,
      getGridBorderClasses,
      t,
      locations,
      location
    };
  }
};
