<template>
  <div v-if="loading" class="flex h-full justify-center items-center">
    <loading-spinner diameter="64px" />
  </div>

  <alert-badge
    v-else-if="error.title"
    isDanger
    :title="error.title"
    :body="error.body"
    @dismissed="
      () => {
        error.title = '';
        error.body = '';
      }
    "
  />
  <div class="container w-auto flex flex-col">
    <div class="schedule-header px-4 pt-4">
      <div class="flex flex-between">
        <div>
          <el-select class="w-full" v-model="location">
            <el-option
              v-for="(loc, i) in locations"
              :key="i"
              :label="$ctx.locale === 'en' ? loc.enName : loc.arName"
              :value="loc.id"
            >
            </el-option>
        </el-select>
        </div>
        <div class="flex justify-end w-full">
          <div class="w-max flex justify-center items-center">
            <font-awesome-icon
              @click="dateOnChange('prev')"
              :icon="$ctx.getDir() == 'rtl' ? 'arrow-right' : 'arrow-left'"
            ></font-awesome-icon>
            <div class="w-48 text-center">
              {{ formatDate() }}
            </div>
            <font-awesome-icon
              @click="dateOnChange('next')"
              :icon="$ctx.getDir() == 'rtl' ? 'arrow-left' : 'arrow-right'"
            ></font-awesome-icon>
          </div>
        </div>
      </div>
      <div class="flex mt-3">
        <div class="w-full bg-white rounded p-1">{{ t("am") }}</div>
        <div class="w-full bg-gray-400 rounded p-1 text-white">
          {{ t("pm") }}
        </div>
      </div>
      <div class="flex w-full justify-between">
        <div class="grid-12 w-full justify-between">
          <div class="w-full"><p>12:00</p></div>
          <div class="w-full"><p>1:00</p></div>
          <div class="w-full"><p>2:00</p></div>
          <div class="w-full"><p>3:00</p></div>
          <div class="w-full"><p>4:00</p></div>
          <div class="w-full"><p>5:00</p></div>
          <div class="w-full"><p>6:00</p></div>
          <div class="w-full"><p>7:00</p></div>
          <div class="w-full"><p>8:00</p></div>
          <div class="w-full"><p>9:00</p></div>
          <div class="w-full"><p>10:00</p></div>
          <div class="w-full"><p>11:00</p></div>
        </div>
        <div class="grid-12 w-full justify-between">
          <div class="w-full"><p>12:00</p></div>
          <div class="w-full"><p>1:00</p></div>
          <div class="w-full"><p>2:00</p></div>
          <div class="w-full"><p>3:00</p></div>
          <div class="w-full"><p>4:00</p></div>
          <div class="w-full"><p>5:00</p></div>
          <div class="w-full"><p>6:00</p></div>
          <div class="w-full"><p>7:00</p></div>
          <div class="w-full"><p>8:00</p></div>
          <div class="w-full"><p>9:00</p></div>
          <div class="w-full"><p>10:00</p></div>
          <div class="w-full"><p>11:00</p></div>
        </div>
      </div>
    </div>

    <div class="relative mx-4 h-full">
      <div class="w-full h-full absolute">
        <div class="flex h-full w-full justify-between">
          <div class="grid-24 h-full w-full">
            <div
              class="h-full w-full"
              :class="
                $ctx.getDir() === 'rtl'
                  ? 'right-dark-border'
                  : 'left-dark-border'
              "
            ></div>

            <div
              v-for="index in 23"
              :key="index"
              :class="getGridBorderClasses()"
            ></div>
          </div>
          <div class="grid-24 h-full w-full">
            <div
              class="h-full w-full"
              :class="
                $ctx.getDir() === 'rtl'
                  ? 'right-dark-border'
                  : 'left-dark-border'
              "
            ></div>
            <div
              v-for="index in 23"
              :key="index"
              :class="getGridBorderClasses()"
            ></div>
          </div>
        </div>
      </div>
      <div class="absolute schedule-items-container w-full">
        <div v-for="(es, index) in scheduleData" class="grid-48" :key="index">
          <div
            class="schedule-item bg-blue-300 h-8 flex justify-between items-center px-4 rounded my-4"
            :style="
              calculateWorkingHours(
                es.startWorkHour,
                es.startWorkMinute,
                es.endWorkHour,
                es.endWorkMinute
              )
            "
          >
            <div>
              <p>{{ es.user?.fullName }}</p>
            </div>
            <div class="text-gray-600 ltr">
              {{ es.startWorkHour }}:{{ es.startWorkMinute }}
              {{ checkAmPm(es.startWorkHour) }} - {{ es.endWorkHour }}:{{
                es.endWorkMinute
              }}
              {{ checkAmPm(es.endWorkHour) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import EmployeeSchedule from "@/models/hr/EmployeeSchedule";
import { onBeforeMount, ref, watch } from "vue-demi";
import { useHTTP } from "@/plugins/http";
import dayjs from "dayjs";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import "dayjs/locale/ar-iq";
import { useContext } from "@/plugins/context";
import { useI18n } from "vue-i18n";
import Location from '@/models/company/Location';

export default {
  components: { LoadingSpinner, AlertBadge },
  name: "view-employee-schedule",

  setup() {
    const messages = {
      en: {
        am: "am",
        pm: "pm"
      },
      ar: {
        am: "صباحا",
        pm: "مساءا"
      }
    };
    const { t } = useI18n({ messages, useScope: "global" });
    const selectedDate = ref(dayjs()); //to get the current day
    const scheduleData = ref(new Array<EmployeeSchedule>());
    const locations = ref(new Array<Location>());
    const location =  ref("");
    const http = useHTTP();
    const loading = ref(true);
    const error = ref({ title: "", body: "" });

    const ctx = useContext();

    const getGridBorderClasses = () => {
      return ctx.getDir() == "rtl"
        ? "right-border h-full w-full"
        : "left-border h-full w-full";
    };

    const formatDate = () => {
      return ctx.getDir() === "rtl"
        ? selectedDate.value.locale("ar-iq").format("dddd, DD/M/YYYY")
        : selectedDate.value.format("dddd, DD/M/YYYY");
    };

    const getLocations = async () => {
      try {
        const locResp = await http.get<Location[]>(Location.ENDPOINT);
        locations.value = locResp.map(loc => Location.from(loc));
      } catch (errorResp) {
        if (errorResp.code === 404) {
          locations.value = [];
        } else {
          error.value.title = errorResp.title;
          error.value.body = errorResp.body;
        }
      }
    }

    const getScheduleData = async () => {
      //the date should be added to the path when the api is ready
      let url = `${
        EmployeeSchedule.ENDPOINT
      }?date=${selectedDate.value.format(
        // server is very sensitive to this format. Keep it this way until we update server.
        "YYYY-MM-DD"
      )}`;
      if (location.value != "") {
        url += `&location_id=${location.value}`;
      }
      try {
        const resp = await http.get<EmployeeSchedule[]>(url);
        scheduleData.value = resp.map(es => EmployeeSchedule.from(es));
      } catch (errorResp) {
        if (errorResp.code === 404) {
          scheduleData.value = [];
        } else {
          error.value.title = errorResp.title;
          error.value.body = errorResp.body;
        }
      }
      loading.value = false;
    };

    onBeforeMount(async () => {
      await getLocations();
      await getScheduleData();
    });

    const checkMinutes = (minutes: number) => {
      return minutes ? 1 : 0;
    };

    const checkAmPm = (hour: number) => {
      return hour >= 12 ? "pm" : "am";
    };

    const calculateWorkingHours = (
      startWorkHour: number,
      startWorkMinute: number,
      endWorkHour: number,
      endWorkMinute: number
    ) => {
      const result =
        "grid-column-start:" +
        (startWorkHour * 2 + 1 + checkMinutes(startWorkMinute)) +
        "; grid-column-end:" +
        (endWorkHour * 2 + 1 + checkMinutes(endWorkMinute)) +
        ";";
      return result;
    };

    const dateOnChange = (action: string) => {
      selectedDate.value =
        action === "prev"
          ? selectedDate.value.subtract(1, "day")
          : selectedDate.value.add(1, "day");
      //the new date should be passed to the function
      getScheduleData();
    };

    watch(()=>location.value, function() {
      getScheduleData();
    });

    return {
      scheduleData,
      calculateWorkingHours,
      checkAmPm,
      formatDate,
      dateOnChange,
      loading,
      error,
      getGridBorderClasses,
      t,
      locations,
      location
    };
  }
};
</script>

<style scoped>
.grid-12 {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.grid-24 {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
}
.grid-48 {
  display: grid;
  grid-template-columns: repeat(48, 1fr);
}
.container {
  background-color: white;
  margin-inline: 1rem;
  height: 100%;
  padding: 0.3rem;
}

.schedule-header {
  background-color: #e5e5e5;
  display: block;
  border-radius: 10px;
}
.left-border:nth-child(odd) {
  border-left: 2px solid #e5e5e5;
}

.left-dark-border {
  border-left: 2px solid #909090;
}

.right-border:nth-child(odd) {
  border-right: 2px solid #e5e5e5;
}

.right-dark-border {
  border-right: 2px solid #909090;
}

.ltr {
  direction: ltr;
}

.schedule-items-container {
  overflow-y: overlay;
  max-height: calc(100vh - 108px - 2rem);
}
</style>
